import styled from "styled-components";

import { Colors, FontFamily } from "@/styles/styles";

export type CardStyleProps = {
  backgroundColor: Colors;
  borderWidth: string;
  borderColor: string;
  borderRadius: string;
  width: string;
  minHeight: string;
  margin: string;
  padding: string;

  desktop: {
    height: string;
    heightLastCard: string;
    margin: string;
    width: string;
    padding: string;
  };
  mobileSmall: {
    minHeight: string;
    padding: string;
  };
};

export const Card = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: ${(props) => props.theme.otherNewsletters.Card.CardStyle.width};
  min-height: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.minHeight};
  box-sizing: border-box;
  flex-direction: column;

  align-items: center;

  padding: ${(props) => props.theme.otherNewsletters.Card.CardStyle.padding};
  border: ${(props) => props.theme.otherNewsletters.Card.CardStyle.borderWidth}
    solid ${(props) => props.theme.otherNewsletters.Card.CardStyle.borderColor};
  margin: ${(props) => props.theme.otherNewsletters.Card.CardStyle.margin};
  background: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.backgroundColor};
  border-radius: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.borderRadius};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.desktop.width};
    min-height: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.desktop.height};
    padding: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.desktop.padding};
    margin: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.desktop.margin};
  }

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    width: 100%;
    min-height: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.mobileSmall.minHeight};
    flex-direction: row;
    padding: ${(props) =>
      props.theme.otherNewsletters.Card.CardStyle.mobileSmall.padding};
  }
`;

export type SponsoredProps = {
  top: string;
  color: Colors;
  fontSize: string;
  fontFamily: FontFamily;
  desktop: {
    top: string;
    fontSize: string;
  };
};
export const Sponsored = styled.div`
  position: absolute;
  top: ${(props) => props.theme.otherNewsletters.Card.Sponsored.top};
  right: 0px;
  color: ${(props) => props.theme.otherNewsletters.Card.Sponsored.color};
  font-family: ${(props) =>
    props.theme.otherNewsletters.Card.Sponsored.fontFamily};
  font-size: ${(props) => props.theme.otherNewsletters.Card.Sponsored.fontSize};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    top: ${(props) => props.theme.otherNewsletters.Card.Sponsored.desktop.top};
    font-size: ${(props) =>
      props.theme.otherNewsletters.Card.Sponsored.desktop.fontSize};
  }
`;

export const ImageWrapper = styled.div<{
  backgroundImage?: string;
}>`
  width: 100%;
  min-height: 92px;
  background-color: white;
  background-image: url("${(props) => props.backgroundImage}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    min-height: 128px;
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    height: 100%;
`;

export type IconWrapperProps = {
  imgWidth: string;
  imgHeight: string;
  desktop: {
    margin: string;
    imgWidth: string;
    imgHeight: string;
  };
  mobileSmall: {
    imgWidth?: string;
    imgHeight?: string;
    marginRight: string;
  };
};
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
  & > img {
    width: ${(props) => props.theme.otherNewsletters.Card.IconWrapper.imgWidth};
    height: ${(props) =>
      props.theme.otherNewsletters.Card.IconWrapper.imgHeight};
  }
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: ${(props) =>
      props.theme.otherNewsletters.Card.IconWrapper.desktop.margin};
    & > img {
      width: ${(props) =>
        props.theme.otherNewsletters.Card.IconWrapper.desktop.imgWidth};
      height: ${(props) =>
        props.theme.otherNewsletters.Card.IconWrapper.desktop.imgHeight};
    }
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    margin-right: ${(props) =>
      props.theme.otherNewsletters.Card.IconWrapper.mobileSmall.marginRight};
    & > img {
      width: ${(props) =>
        props.theme.otherNewsletters.Card.IconWrapper.mobileSmall?.imgWidth};
      height: ${(props) =>
        props.theme.otherNewsletters.Card.IconWrapper.mobileSmall?.imgHeight};
    }
  }
`;

export type TextWrapperProps = {
  mobileSmall: {
    alignItems: string;
    padding: string;
  };
};

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  padding: ${(props) => props.theme.otherNewsletters.Card.TextWrapper.padding};
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: stretch;

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    align-items: ${(props) =>
      props.theme.otherNewsletters.Card.TextWrapper.mobileSmall.alignItems};
    padding: ${(props) =>
      props.theme.otherNewsletters.Card.TextWrapper.mobileSmall.padding};
  }
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: ${(props) =>
      props.theme.otherNewsletters.Card.TextWrapper.desktop.padding};
  }
`;
export type TitleWrapperProps = {
  margin?: string;
  desktop?: {
    margin?: string;
  };
  mobileSmall: {
    minHeight: string;
    justifyContent?: string;
    width?: string;
  };
};
export const TitleWrapper = styled.div`
  display: flex;
  min-height: 28px;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.otherNewsletters.Card.TitleWrapper?.margin};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: ${(props) =>
      props.theme.otherNewsletters.Card.TitleWrapper.desktop?.margin};
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    width: ${(props) =>
      props.theme.otherNewsletters.Card.TitleWrapper.mobileSmall?.width};
    min-height: ${(props) =>
      props.theme.otherNewsletters.Card.TitleWrapper.mobileSmall.minHeight};
    justify-content: ${(props) =>
      props.theme.otherNewsletters.Card.TitleWrapper.mobileSmall
        ?.justifyContent};
  }
`;

export type TitleProps = {
  color: Colors;
  fontFamily: FontFamily;
  width?: string;
  fontSize: string;
  margin?: string;
  desktop: {
    width?: string;
    fontSize: string;
    fontWeight?: number;
  };
  mobileSmall?: {
    fontSize: string;
    textAlign: string;
    margin: string;
  };
};

export const Title = styled.h3`
  width: ${(props) => props.theme.otherNewsletters.Card.Title?.width};
  margin: ${(props) => props.theme.otherNewsletters.Card.Title?.margin};
  margin: ${(props) =>
    props.theme.otherNewsletters.Card.Title.mobileSmall?.margin};
  color: ${(props) => props.theme.otherNewsletters.Card.Title.color};
  font-family: ${(props) => props.theme.otherNewsletters.Card.Title.fontFamily};
  font-size: ${(props) => props.theme.otherNewsletters.Card.Title.fontSize};
  text-align: center;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: ${(props) => props.theme.otherNewsletters.Card.Title.desktop?.width};
    margin: 0px;
    font-size: ${(props) =>
      props.theme.otherNewsletters.Card.Title.desktop.fontSize};
    font-weight: ${(props) =>
      props.theme.otherNewsletters.Card.Title.desktop?.fontWeight};
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    font-size: ${(props) =>
      props.theme.otherNewsletters.Card.Title.mobileSmall?.fontSize};
    text-align: ${(props) =>
      props.theme.otherNewsletters.Card.Title.mobileSmall?.textAlign};
  }
`;

export type InfoIconButtonProps = {
  display: string;
  position?: string;
  top?: string;
  width: string;
  height: string;
  borderRadius?: string;
  mobileSmall?: {
    position?: string;
  };
};

export const InfoIconButton = styled.button`
  position: ${(props) =>
    props.theme.otherNewsletters.Card.InfoIconButton?.position};
  top: ${(props) => props.theme.otherNewsletters.Card.InfoIconButton?.top};
  display: ${(props) =>
    props.theme.otherNewsletters.Card.InfoIconButton.display};
  width: ${(props) => props.theme.otherNewsletters.Card.InfoIconButton.width};
  height: ${(props) => props.theme.otherNewsletters.Card.InfoIconButton.height};
  padding: 0;
  border: none;

  background-color: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.backgroundColor};
  border-radius: ${(props) =>
    props.theme.otherNewsletters.Card.InfoIconButton?.borderRadius};
  & svg {
    width: 100%;
    height: 100%;
  }
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: none;
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    position: ${(props) =>
      props.theme.otherNewsletters.Card.InfoIconButton.mobileSmall?.position};
  }
`;
export type InfoboxModalProps = {
  visible?: boolean;
  borderWidth?: string;
  borderColor?: string;
  borderRadius?: number;
  fontSize: string;
  padding: string;
};

export const InfoboxModal = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.otherNewsletters.Card.InfoboxModal.padding};

  border: ${(props) => props.theme.otherNewsletters.Card.CardStyle.borderWidth}
    solid ${(props) => props.theme.otherNewsletters.Card.CardStyle.borderColor};
  background: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.backgroundColor};
  border-radius: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.borderRadius}px;
  font-size: ${(props) =>
    props.theme.otherNewsletters.Card.InfoboxModal.fontSize};

  opacity: 0;
  text-align: center;

  transition: visibility 0.4s, opacity 0.4s ease;
  visibility: hidden;

  ${(props) =>
    props.isVisible === true &&
    `
        visibility: visible;
        opacity: 100;
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
        z-index: 5;
    `}

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: none;
  }
`;

export type ScheduleProps = {
  margin: string;
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  fontWeight: number;
  desktop: {
    fontSize: string;
  };
  mobileSmall: {
    fontSize: string;
  };
};

export const ScheduleWrapper = styled.div`
  display: flex;
  min-height: 21px;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.otherNewsletters.Card.Schedule.margin};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    min-height: 24px;
  }
`;

export const Schedule = styled.div`
  color: ${(props) => props.theme.otherNewsletters.Card.Schedule.color};
  font-family: ${(props) =>
    props.theme.otherNewsletters.Card.Schedule.fontFamily};
  font-size: ${(props) => props.theme.otherNewsletters.Card.Schedule.fontSize};
  font-weight: ${(props) =>
    props.theme.otherNewsletters.Card.Schedule.fontWeight};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin-left: 10px;
    font-size: ${(props) =>
      props.theme.otherNewsletters.Card.Schedule.desktop.fontSize};
  }
  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    font-size: ${(props) =>
      props.theme.otherNewsletters.Card.Schedule.mobileSmall.fontSize};
  }
`;

export const ScheduleIconWrapper = styled.div`
  display: none;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: flex;
  }
`;

export type TextProps = {
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  textAlign: string;
  lineHeight: string;
  desktop: {
    padding: string;
  };
};

export const Text = styled.div`
  display: none;
  color: ${(props) => props.theme.otherNewsletters.Card.Text.color};
  font-family: ${(props) => props.theme.otherNewsletters.Card.Text.fontFamily};
  font-size: ${(props) => props.theme.otherNewsletters.Card.Text.fontSize};
  line-height: ${(props) => props.theme.otherNewsletters.Card.Text.lineHeight};
  text-align: center;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: flex;
    flex-grow: 1;
    padding: ${(props) =>
      props.theme.otherNewsletters.Card.Text.desktop.padding};
  }
`;

export type CloseIconProps = {
  backgroundColor: Colors;
};

export const CloseIconButton = styled.button`
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: none;
  }
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  padding: 0;
  border: none;
  margin-bottom: 8px;
  background-color: ${(props) =>
    props.theme.otherNewsletters.Card.CardStyle.backgroundColor};
`;
