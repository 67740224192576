import { PayloadAction } from "@reduxjs/toolkit";
import { AbortableFetch, ApiResponse, isOk } from "@weltn24/la-frontend-utils";
import { call, getContext, put, takeLatest } from "redux-saga/effects";

import { INoSSOPaperboyApi } from "@/services/api/NoSSOPaperboyApi";
import { BatchSubscriptionRequest, Newsletter } from "@/services/Model";
import { log } from "@/utils/logger";

import {
  fetchNewslettersFailure,
  fetchNewslettersInit,
  fetchNewslettersSuccess,
  submittingFailure,
  submittingInit,
  submittingSuccess,
} from "./slice";

export function* fetchNewsletters(
  action: PayloadAction<{ authToken?: string }>
) {
  try {
    const paperboyApi: INoSSOPaperboyApi = yield getContext("paperboyApi");
    const request: AbortableFetch<
      ApiResponse<Newsletter[] | undefined>
    > | null = yield paperboyApi.getNewsletters(action.payload.authToken);
    const data: Newsletter[] | undefined = yield request?.ready.then(
      (data) => data.data
    );
    if (data) {
      yield put(fetchNewslettersSuccess({ newsletters: data }));
    } else {
      throw new Error("Newsletter response is empty");
    }
  } catch (error) {
    log.error(`Could not get newsletters:`, error);
    yield put(fetchNewslettersFailure(error));
  }
}

export function* submitSubscriptions(
  action: PayloadAction<{
    requestBody: BatchSubscriptionRequest;
    authToken: string;
  }>
) {
  try {
    const paperboyApi: INoSSOPaperboyApi = yield getContext("paperboyApi");
    yield call(() =>
      paperboyApi
        .batchSubscription(action.payload.requestBody, action.payload.authToken)
        .then(isOk)
    );
    yield put(submittingSuccess());
  } catch (error) {
    yield put(submittingFailure(error));
    log.error(`Batch subscription failed:`, error);
  }
}

export function* watchFetchNewsletters() {
  yield takeLatest(fetchNewslettersInit, fetchNewsletters);
}

export function* watchSubmitting() {
  yield takeLatest(submittingInit, submitSubscriptions);
}
