import React from "react";

import SmartCTAButton from "@/components/SmartCTAButton";
import WeltPlusBanner from "@/components/WeltPlusBanner";
import {
  StyledCardIntro,
  StyledCardTitle,
  StyledCardTransmissionTime,
} from "@/sections/TopicNewsletter/TopicNewsletterCard/styles";
import { Newsletter } from "@/services/Model";

import {
  StyledFeaturedNewsletterCard,
  StyledFeaturedNewsletterCardFooter,
  StyledPlaceholder,
} from "./styles";

export type FeaturedNewsletterCardProps = {
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
  newsletter: Newsletter;
};

const FeaturedNewsletterCard: React.FC<FeaturedNewsletterCardProps> = (
  props: FeaturedNewsletterCardProps
): JSX.Element => {
  const { handleNewsletterClick, newsletter } = props;

  return (
    <StyledFeaturedNewsletterCard
      className="newsletter-card"
      data-qa="FeaturedNewsletter.Card"
    >
      <div>
        {newsletter.paidNewsletter ? (
          <WeltPlusBanner dataQa="FeaturedNewsletter.Card.WeltPlusBanner" />
        ) : (
          <StyledPlaceholder />
        )}
        <StyledCardTitle
          className="featured"
          data-qa="FeaturedNewsletter.Card.Title"
        >
          {newsletter.title}
        </StyledCardTitle>
        <StyledCardIntro data-qa="FeaturedNewsletter.Card.Intro">
          {newsletter.heroIntro ? newsletter.heroIntro : newsletter.intro}
        </StyledCardIntro>
      </div>

      <StyledFeaturedNewsletterCardFooter>
        <SmartCTAButton
          dataQa="FeaturedNewsletter.Card.Button"
          newsletter={newsletter}
          handleNewsletterClick={handleNewsletterClick}
        />
        <StyledCardTransmissionTime
          data-qa="FeaturedNewsletter.Card.TransmissionTime"
          className="featured"
        >
          {newsletter.transmissionTime}
        </StyledCardTransmissionTime>
      </StyledFeaturedNewsletterCardFooter>
    </StyledFeaturedNewsletterCard>
  );
};

export default FeaturedNewsletterCard;
