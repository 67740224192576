import React from "react";

import { HeaderHeadlineWrapper, Headline, Subtitle } from "./styles";

export type HeaderHeadlinesProps = {
  title: string;
  subtitle: string;
};

const HeaderHeadlines: React.FC<HeaderHeadlinesProps> = (
  props: HeaderHeadlinesProps
): JSX.Element => (
  <HeaderHeadlineWrapper>
    <Subtitle>{props.subtitle}</Subtitle>
    <Headline>{props.title}</Headline>
  </HeaderHeadlineWrapper>
);

export default HeaderHeadlines;
