import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";

import { ConfigContext, Views } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import {
  Description,
  DescriptionHeadline,
  DescriptionList,
  HeaderTextWrapper,
} from "./styles";

export type RegistrationHeaderTextProps = {
  descriptionItems?: string[];
  descriptionHeadline?: string;
  view: Views;
};

const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckmarkCircle = styled(SVG)`
  @media ${(props) => props.theme.global.breakpoints.mobile} {
    width: 20px;
    height: 20px;
  }
`;

const RegistrationHeaderText: React.FC<RegistrationHeaderTextProps> = (
  props: RegistrationHeaderTextProps
): JSX.Element => {
  const { iconSourceURL } = useContext(ConfigContext);
  return (
    <HeaderTextWrapper data-qa="RegistrationHeaderText" view={props.view}>
      <DescriptionHeadline>{props.descriptionHeadline}</DescriptionHeadline>
      <DescriptionList>
        {props.descriptionItems?.map((description, index) => (
          <Description key={`RegistrationHeaderText.description.${index}`}>
            <SVGWrapper>
              <CheckmarkCircle
                src={`${iconSourceURL}${Icons.CheckmarkCircle}.svg`}
              />
            </SVGWrapper>
            {description}
          </Description>
        ))}
      </DescriptionList>
    </HeaderTextWrapper>
  );
};

export default RegistrationHeaderText;
