import React, { memo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";

import StyledButton from "@/components/StyledButton";
import { ConfigContext } from "@/factory/apps/App";
import {
  Newsletter,
  NewsletterUser,
  NewsletterWithStatus,
} from "@/services/Model";
import { useAppSelector } from "@/store/hooks";
import { selectedNewsletterSelector } from "@/store/newsletter/slice";
import { userSelector } from "@/store/user/slice";
import { Icons } from "@/styles/styles";

import { StyledInformationNote, StyledInformationWrapper } from "./styles";

const getSmartCTAText = (
  subscribed: boolean,
  paidNewsletter: boolean,
  premiumUser: boolean,
  loggedIn: boolean
): string => {
  if (subscribed) {
    return "Abbestellen";
  }

  if (!loggedIn) {
    return "Anmelden und Abonnieren";
  }

  if (!premiumUser && paidNewsletter) {
    return "Jetzt kostenlos testen";
  }

  return "Abonnieren";
};

type SmartCTAButtonProps = {
  newsletter: Newsletter;
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
  className?: string;
  dataQa?: string;
};

const SmartCTAButton: React.FC<SmartCTAButtonProps> = memo(
  function SmartCTAButtonComponent(props: SmartCTAButtonProps): JSX.Element {
    const { newsletter, handleNewsletterClick, dataQa } = props;
    const { subscribed, newsletterID, paidNewsletter } = newsletter;

    const { iconSourceURL } = React.useContext(ConfigContext);
    const user: NewsletterUser = useAppSelector(userSelector);
    const selectedNewsletter: NewsletterWithStatus = useAppSelector((state) =>
      selectedNewsletterSelector(state, newsletter.newsletterID)
    );

    const [buttonText, setButtonText] = useState<string>(
      getSmartCTAText(
        subscribed,
        !!paidNewsletter,
        user.isPremium,
        user.isLoggedIn
      )
    );

    useEffect(() => {
      setButtonText(
        getSmartCTAText(
          subscribed,
          !!paidNewsletter,
          user.isPremium,
          user.isLoggedIn
        )
      );
    }, [subscribed, paidNewsletter, user.isPremium, user.isLoggedIn]);

    if (selectedNewsletter.subscribeSuccess) {
      return (
        <StyledInformationWrapper data-qa="SmartCTAButton.ThankYouNote">
          <StyledInformationNote>Vielen Dank.</StyledInformationNote>
          <SVG src={`${iconSourceURL}${Icons.CheckmarkCircle}.svg`} />
        </StyledInformationWrapper>
      );
    }

    if (selectedNewsletter.isLoading) {
      return (
        <StyledInformationWrapper data-qa="SmartCTAButton.Waiting">
          <StyledInformationNote className="pulse">
            warten ....
          </StyledInformationNote>
        </StyledInformationWrapper>
      );
    }

    if (selectedNewsletter.hasError) {
      return (
        <StyledInformationWrapper data-qa="SmartCTAButton.Error">
          <StyledInformationNote>
            Ups! Etwas ist schief gelaufen
          </StyledInformationNote>
        </StyledInformationWrapper>
      );
    }

    return (
      <StyledButton
        dataQa={dataQa}
        className={`
          ${subscribed ? "subscribed fade-in" : "fade-in"}
        `}
        onClick={() => {
          handleNewsletterClick(newsletterID, !subscribed);
        }}
      >
        {buttonText}
      </StyledButton>
    );
  }
);

export default SmartCTAButton;
