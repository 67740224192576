import React from "react";

import Schedule from "@/components/Schedule";
import SectionHeader from "@/components/SectionHeader";
import { NoSSOGlobalContext, Views } from "@/factory/apps/NoSSOApp";
import { Newsletter } from "@/services/Model";

import {
  SelectedNewsletterCard,
  SelectedNewslettersHeaderWrapper,
  SelectedNewslettersSection,
  SelectedNewslettersWrapper,
  SelectedNewsletterTitle,
} from "./styles";

type SelectedNewsletters = {
  newsletters?: Newsletter[];
};

const SelectedNewsletters: React.FC<SelectedNewsletters> = (
  props: SelectedNewsletters
): JSX.Element => {
  const { ConfirmationTexts } = React.useContext(NoSSOGlobalContext);
  return (
    <SelectedNewslettersSection>
      <SelectedNewslettersHeaderWrapper>
        <SectionHeader view={Views.Confirmation}>
          {ConfirmationTexts.sectionHeader}
        </SectionHeader>
      </SelectedNewslettersHeaderWrapper>

      <SelectedNewslettersWrapper>
        {props.newsletters?.map((newsletter: Newsletter) => (
          <SelectedNewsletterCard
            key={`newsletter-${newsletter.newsletterID}`}
            data-qa={"SelectedNewsletter"}
          >
            <SelectedNewsletterTitle>
              {newsletter.title}
            </SelectedNewsletterTitle>
            <Schedule>{newsletter.transmissionTime}</Schedule>
          </SelectedNewsletterCard>
        ))}
      </SelectedNewslettersWrapper>
    </SelectedNewslettersSection>
  );
};
export default SelectedNewsletters;
