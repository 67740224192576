import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import React, { useState } from "react";
import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Headline from "@/components/Headline";
import { ContentContext } from "@/factory/apps/App";
import { Newsletter } from "@/services/Model";
import { useAppSelector } from "@/store/hooks";
import { newsletterSelector } from "@/store/newsletter/slice";

import FeaturedNewsletterCard from "./FeaturedNewsletterCard";
import { StyledGradient } from "./FeaturedNewsletterCard/styles";
import { StyledFeaturedBackgroundImage, StyledFeaturedWrapper } from "./styles";

const FEATURED_NEWSLETTER_CATEGORY = "featured";

export type FeaturedNewsletterProps = {
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const FeaturedNewsletter: React.FC<FeaturedNewsletterProps> = (
  props: FeaturedNewsletterProps
): JSX.Element => {
  const {
    FeaturedContext: { headline },
  } = React.useContext(ContentContext);
  const { handleNewsletterClick } = props;
  const newsletters = useAppSelector(newsletterSelector);
  const featuredNewsletters = newsletters.filter((newsletter) =>
    newsletter.categories.includes(FEATURED_NEWSLETTER_CATEGORY)
  );

  const [cardSwiper, setCardSwiper] = useState<SwiperCore | null>(null);
  const [imageSwiper, setImageSwiper] = useState<SwiperCore | null>(null);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const handleSlideChange = (swiper: SwiperCore) => {
    setActiveSlide(swiper.activeIndex);
  };

  return (
    <section>
      <Headline dataQa="FeaturedNewsletter.Headline">{headline}</Headline>

      <StyledFeaturedWrapper>
        <Swiper
          onSwiper={(swiper: SwiperCore) => setImageSwiper(swiper)}
          controller={{ control: cardSwiper }}
          centeredSlides={true}
          effect="fade"
          speed={1500}
          modules={[EffectFade, Controller]}
          className="featured-newsletter-background-image-swiper"
        >
          {featuredNewsletters.map((newsletter: Newsletter) => (
            <SwiperSlide
              key={`featured-newsletter-image-${newsletter.newsletterID}`}
            >
              <StyledFeaturedBackgroundImage
                className="background-image"
                src={newsletter.heroImageURL ? newsletter.heroImageURL : ""}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          onSwiper={(swiper: SwiperCore) => setCardSwiper(swiper)}
          controller={{ control: imageSwiper }}
          slidesPerView={"auto"}
          navigation={true}
          centeredSlides={true}
          speed={1500}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation, Pagination, Controller]}
          onSlideChange={handleSlideChange}
          className="featured-newsletter-card-swiper"
        >
          {activeSlide !== 0 && <StyledGradient className="left" />}
          {featuredNewsletters.map((newsletter: Newsletter) => (
            <SwiperSlide
              key={`featured-newsletter-card-${newsletter.newsletterID}`}
            >
              <FeaturedNewsletterCard
                newsletter={newsletter}
                handleNewsletterClick={handleNewsletterClick}
              />
            </SwiperSlide>
          ))}
          {activeSlide !== featuredNewsletters.length - 1 && (
            <StyledGradient className="right" />
          )}
        </Swiper>
      </StyledFeaturedWrapper>
    </section>
  );
};

export default FeaturedNewsletter;
