import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import {
  StyledCheckBox,
  StyledCheckBoxLabel,
  StyledCheckBoxWrap,
} from "./styles";

export type CheckBoxProps = {
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  disabled?: boolean;
};

const CheckBox: React.FC<CheckBoxProps> = (
  props: CheckBoxProps
): JSX.Element => {
  const [checked, setChecked] = React.useState<boolean>(props.isChecked);

  useEffect(() => {
    setChecked(checked || props.isChecked);
  }, [props.isChecked]);
  const { iconSourceURL } = React.useContext(ConfigContext);
  return (
    <StyledCheckBoxLabel>
      <StyledCheckBoxWrap>
        {checked && <SVG src={`${iconSourceURL}${Icons.Checkmark}.svg`} />}
        <StyledCheckBox
          checked={checked}
          disabled={props.disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            setChecked(checked);
            props.onChange(checked);
          }}
        />
      </StyledCheckBoxWrap>
    </StyledCheckBoxLabel>
  );
};

export default CheckBox;
