import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import { InfoIconButton } from "./styles";

export type InfoButtonProps = {
  onClick: () => void;
  children?: ReactNode;
};

const InfoButton: React.FC<InfoButtonProps> = (
  props: InfoButtonProps
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);

  return (
    <InfoIconButton onClick={() => props.onClick()}>
      <SVG src={`${iconSourceURL}${Icons.Info}.svg`} />
      {props.children}
    </InfoIconButton>
  );
};

export default InfoButton;
