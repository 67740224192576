import styled from "styled-components";

import { Colors } from "@/styles/styles";

import { SectionHeaderWrapper } from "../FeaturedNewsletters/styles";

export type OtherNewsletterSectionProps = {
  backgroundColor?: Colors;
  justifyContent: string;
  width: string;
  desktop?: {
    justifyContent?: string;
  };
};

export const OtherNewsletterSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export type OtherNewslettersSectionHeaderWrapperProps = {
  display: string;
  margin?: string;
  desktop: {
    margin: string;
  };
};

export const OtherNewslettersSectionHeaderWrapper = styled(
  SectionHeaderWrapper
)`
  display: ${(props) => props.theme.otherNewsletters.Header.display};
  margin: ${(props) => props.theme.otherNewsletters.Header?.margin};
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    display: flex;
    margin: ${(props) => props.theme.otherNewsletters.Header.desktop.margin};
  }
`;

export const OtherNewsletterListWrapper = styled.div`
  width: ${(props) => props.theme.otherNewsletters.Section.width};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 100%;
  }

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    width: 100%;
  }
`;

export const OtherNewsletterList = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.theme.otherNewsletters.Section.justifyContent};
  margin: 0 -6px;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    justify-content: ${(props) =>
      props.theme.otherNewsletters.Section.desktop?.justifyContent};
    margin: 0 -8px;
  }
`;
