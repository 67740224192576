import styled from "styled-components";

export const StyledFeaturedNewsletterCard = styled.div`
  width: 100%;
  height: 318px;

  position: absolute;
  top: 0px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  z-index: 20;
  background: ${(props) => props.theme.color.white};
  border-radius: ${(props) => props.theme.radius.m};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 24px rgba(0, 0, 0, 0.1);
  padding: ${(props) => props.theme.padding.m};

  p {
    margin: 0 auto;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 600px;
    height: 306px;
    padding: 30px 0;
  }
`;

export const StyledFeaturedNewsletterCardFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    text-align: left;
  }
`;

export const StyledPlaceholder = styled.div`
  width: 100%;
  height: 20px;
`;

export const StyledGradient = styled.div`
  position: absolute;
  width: 20px;
  height: 340px;
  bottom: 0;
  z-index: 12000;

  &.left {
    left: 0;
    background: linear-gradient(270deg, #fff, 60%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &.right {
    right: 0;
    background: linear-gradient(270deg, #fff, 60%, rgba(255, 255, 255, 0) 100%);
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 70px;
    opacity: 1;
  }
`;
