import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Brands, { getBrandIdentifier } from "@/Brands";
// import global context to access the text content
import {
  ConfigContext,
  NoSSOGlobalContext,
  Views,
} from "@/factory/apps/NoSSOApp";
import FeaturedNewsletters from "@/noSSOPartials/FeaturedNewsletters";
import Header from "@/noSSOPartials/Header";
import OtherNewsletters from "@/noSSOPartials/OtherNewsletters";
import RegistrationBox from "@/noSSOPartials/RegistrationBox";
import { Routes } from "@/routes";
import { getAuthenticationToken } from "@/services/Authentication";
import { BatchSubscriptionRequest, Newsletter } from "@/services/Model";
import { useAppDispatch, useAppSelector } from "@/store/noSSO/hooks";
import {
  authenticatedSetSubscription,
  fetchNewslettersInit,
  newsletterSliceSelector,
  setSubmitted,
  setSubscription,
  submittingInit,
} from "@/store/noSSO/newsletter/slice";
import {
  fetchUserinfoInit,
  setMediaOptIn,
  userSliceSelector,
} from "@/store/noSSO/user/slice";
import { blockCenterRelative, SpinningAnimation } from "@/styles/common";
import { Icons } from "@/styles/styles";

export type MainContentProps = {
  maxWidth: string;
};

const MainContent = styled.div`
  ${blockCenterRelative}
  max-width:${(props) => props.theme.mainContent.maxWidth};
  margin: 0 12px;
  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 0 auto;
  }
`;

export const StyledSpinner = styled.div`
  svg {
    display: block;
    width: 150px;
    margin: 0 auto;
    animation: ${SpinningAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    fill: black;
  }
`;

const Registration: React.FC = (): JSX.Element => {
  const history = useHistory();
  const authToken = getAuthenticationToken();
  const { RegistrationTexts, FinePrintTexts } =
    React.useContext(NoSSOGlobalContext);
  const { iconSourceURL, subscriptionSource } = React.useContext(ConfigContext);

  const dispatch = useAppDispatch();

  const userStore = useAppSelector(userSliceSelector);
  const newsletterStore = useAppSelector(newsletterSliceSelector);

  useEffect(() => {
    if (authToken !== undefined) {
      dispatch(fetchUserinfoInit({ authToken }));
    }
    dispatch(fetchNewslettersInit({ authToken }));
  }, []);

  useEffect(() => {
    if (newsletterStore.submitted) {
      const successView = userStore.authToken
        ? Routes.Edited
        : Routes.Confirmation;
      const selectedNewsletters: Newsletter[] =
        newsletterStore.newsletters.filter(
          (newsletter: Newsletter) => newsletter.subscribed
        );

      history.push({
        pathname: successView,
        state: {
          authToken: userStore.authToken,
          newsletters: selectedNewsletters,
        },
      });

      dispatch(setSubmitted({ submitted: false }));
    }
  }, [newsletterStore.submitted]);

  const handleNewsletterClick = (selectedNewsletter: {
    newsletterID: number;
    subscribed: boolean;
  }): void => {
    dispatch(
      !userStore.authToken
        ? setSubscription({ selectedNewsletter })
        : authenticatedSetSubscription({ selectedNewsletter })
    );
  };

  const handleSubmit = (email: string) => {
    if (!email) return;

    const requestBody: BatchSubscriptionRequest = {
      email: email,
      subscriptions: newsletterStore.subscriptions,
      source: subscriptionSource,
      sourceURL: window.location.href,
      advertisementOptIn: userStore.advertisementOptIn,
    };

    // some brands require advertisement opt-in text to be sent with the subscription
    // https://as-jira.axelspringer.de/browse/LA-910
    const whichBrand = getBrandIdentifier();
    if (whichBrand === Brands.Aubi || whichBrand === Brands.Cobi) {
      requestBody.advertisementOptInText = FinePrintTexts.data;
    }

    dispatch(
      submittingInit({
        requestBody: requestBody,
        authToken: userStore.authToken,
      })
    );
  };

  const setAdvertisementOptIn = (advertisementOptIn: boolean): void => {
    if (sponsoredNewsletters.length > 0) {
      sponsoredNewsletters.forEach((newsletter: Newsletter) => {
        handleNewsletterClick({
          newsletterID: newsletter.newsletterID,
          subscribed: advertisementOptIn,
        });
      });
    }
    dispatch(setMediaOptIn({ advertisementOptIn }));
  };

  const sponsoredNewsletters: Newsletter[] = newsletterStore.newsletters.filter(
    (newsletter: Newsletter) => newsletter.sponsored
  );

  const categories = Array.from(
    new Set(
      ([] as string[]).concat(
        ...newsletterStore.newsletters.map(
          (item: Newsletter) => item.categories
        )
      )
    ).values()
  );

  if (userStore.isLoadingUser || newsletterStore.isLoadingNewsletter) {
    return (
      <React.Fragment>
        <StyledSpinner>
          <SVG src={`${iconSourceURL}${Icons.Spinner}.svg`} />
        </StyledSpinner>
      </React.Fragment>
    );
  }

  if (userStore.isErrorUser || newsletterStore.isErrorNewsletter) {
    throw new Error("An Error occurred while fetching data.");
  }

  return (
    <React.Fragment>
      <Header
        title={RegistrationTexts.title}
        subtitle={RegistrationTexts.subtitle}
        descriptionHeadline={RegistrationTexts.descriptionHeadline}
        descriptionItems={RegistrationTexts.descriptionItems}
        view={Views.Registration}
      />
      <MainContent>
        {categories.map((category: string, index) => {
          const sectionNewsletters = newsletterStore.newsletters.filter(
            (newsletter) => newsletter.categories.includes(category)
          );
          if (category === "featured") {
            return (
              <FeaturedNewsletters
                key={`FeaturedNewsLetter.${index}`}
                featuredNewsletters={sectionNewsletters}
                handleNewsletterClick={handleNewsletterClick}
              />
            );
          }
          return (
            <OtherNewsletters
              key={`OtherNewsletters.${index}`}
              sectionHeader={
                RegistrationTexts.sectionHeaders[
                  category as keyof typeof RegistrationTexts.sectionHeaders
                ] || RegistrationTexts.sectionHeaders.topic
              }
              newsletters={sectionNewsletters}
              handleNewsletterClick={handleNewsletterClick}
            />
          );
        })}
      </MainContent>
      <RegistrationBox
        onSubmit={handleSubmit}
        setAdvertisementOptIn={setAdvertisementOptIn}
      />
    </React.Fragment>
  );
};

export default Registration;
