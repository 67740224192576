import React from "react";

import SectionHeader from "@/components/SectionHeader";
import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Newsletter, Subscription } from "@/services/Model";

import FeaturedNewsletterCard from "./FeaturedNewsletterCard";
import { FeaturedNewsletterSection, SectionHeaderWrapper } from "./styles";

export type FeaturedNewsletterProps = {
  featuredNewsletters: Newsletter[];
  handleNewsletterClick: (selectedNewsletter: Subscription) => void;
};

const FeaturedNewsletters: React.FC<FeaturedNewsletterProps> = (
  props: FeaturedNewsletterProps
): JSX.Element => {
  const { hasTopNewslettersNumber } = React.useContext(ConfigContext);
  const { featuredNewsletters, handleNewsletterClick } = props;

  if (featuredNewsletters.length == 0) {
    return <br />;
  }

  return (
    <FeaturedNewsletterSection>
      <SectionHeaderWrapper>
        <SectionHeader>
          {hasTopNewslettersNumber
            ? `Unsere Top ${featuredNewsletters.length} Newsletter`
            : "Unsere Top-Newsletter"}
        </SectionHeader>
      </SectionHeaderWrapper>
      {featuredNewsletters.map((newsletter, index) => (
        <FeaturedNewsletterCard
          {...newsletter}
          key={`newsletter-${newsletter.newsletterID}`}
          reverse={index % 2 == 1}
          handleNewsletterClick={handleNewsletterClick}
        />
      ))}
    </FeaturedNewsletterSection>
  );
};

export default FeaturedNewsletters;
