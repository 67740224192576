import { PayloadAction } from "@reduxjs/toolkit";
import { AbortableFetch, ApiResponse } from "@weltn24/la-frontend-utils";
import { call, getContext, put, takeLatest } from "redux-saga/effects";

import { INoSSOPaperboyApi } from "@/services/api/NoSSOPaperboyApi";
import { UserInfo } from "@/services/Model";
import { log } from "@/utils/logger";

import {
  fetchUserinfoFailure,
  fetchUserinfoInit,
  fetchUserinfoSuccess,
} from "./slice";

export function* fetchUserInfo(action: PayloadAction<{ authToken: string }>) {
  try {
    if (!action.payload.authToken) return;
    const paperboyApi: INoSSOPaperboyApi = yield getContext("paperboyApi");
    const request: AbortableFetch<ApiResponse<UserInfo | undefined>> | null =
      yield call(() => paperboyApi.getUserInfo(action.payload.authToken));
    const info: UserInfo | undefined = yield request?.ready.then(
      (data) => data.data
    );
    if (info) {
      if (info.loggedIn) {
        yield put(
          fetchUserinfoSuccess({
            authToken: action.payload.authToken,
            email: info.email,
            advertisementOptIn: info.advertisementOptIn,
            loggedIn: info.loggedIn,
          })
        );
      } else {
        throw new Error("Invalid authentication Token was provided");
      }
    } else {
      throw new Error("User information response is empty");
    }
  } catch (error) {
    log.error(`Could not get user info:`, error);
    yield put(fetchUserinfoFailure(error));
  }
}

export function* watchFetchUserInfo() {
  yield takeLatest(fetchUserinfoInit, fetchUserInfo);
}
