import React from "react";

import StyledButton from "@/components/StyledButton";
import WeltPlusBanner from "@/components/WeltPlusBanner";
import { ContentContext } from "@/factory/apps/App";
import { StyledCardImageWrapper } from "@/sections/TopicNewsletter/TopicNewsletterCard/styles";
import { Newsletter } from "@/services/Model";

import {
  StyledCardImage,
  StyledNewsletterCard,
  StyledNewsletterCardContent,
  StyledNewsletterCardTitle,
  StyledNewsletterCardTransmissionTime,
} from "./styles";

type HeaderNewsletterCardProps = {
  className: string;
  newsletter: Newsletter;
  handleNewsletterClick: (newsletterID: number, subscribe: boolean) => void;
};

const HeaderNewsletterCard: React.FC<HeaderNewsletterCardProps> = (
  props: HeaderNewsletterCardProps
): JSX.Element => {
  const {
    title,
    transmissionTime,
    subscribed,
    newsletterID,
    imageURL,
    paidNewsletter,
  } = props.newsletter;

  const { SubscribeButtonContext } = React.useContext(ContentContext);

  return (
    <StyledNewsletterCard
      className={props.className}
      data-qa="Header.NewsletterCard"
    >
      <StyledCardImageWrapper>
        {paidNewsletter && (
          <WeltPlusBanner
            className="absolute-bottom-left"
            dataQa="Header.WeltPlusBanner"
          />
        )}
        <StyledCardImage src={imageURL ? imageURL : ""} />
      </StyledCardImageWrapper>
      <StyledNewsletterCardContent>
        <StyledNewsletterCardTitle>{title}</StyledNewsletterCardTitle>
        <StyledNewsletterCardTransmissionTime>
          {transmissionTime}
        </StyledNewsletterCardTransmissionTime>

        <StyledButton
          dataQa="Header.UnsubscribeButton"
          className={`slim bottom-right ${subscribed ? "subscribed" : ""}`}
          onClick={() => props.handleNewsletterClick(newsletterID, !subscribed)}
        >
          {SubscribeButtonContext.subscribed}
        </StyledButton>
      </StyledNewsletterCardContent>
    </StyledNewsletterCard>
  );
};

export default HeaderNewsletterCard;
