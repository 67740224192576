import {
  AnyAction,
  CombinedState,
  configureStore,
  Dispatch,
} from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { WhoamiApi, WhoamiWrapper } from "@weltn24/la-frontend-utils";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";

import { getApiUrl } from "@/apiConfig";
import PaperboyApi, { IPaperboyApi } from "@/services/api/PaperboyApi";
import { log } from "@/utils/logger";

import { NewsletterState } from "./newsletter/slice";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const requestTimeout = 30000;

// create saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, { sagaStack }) => {
    log.error(`Uncaught Saga error: ${error.toString()}`, { sagaStack });
  },
});

export const createStore = (): ToolkitStore<
  CombinedState<{
    newsletter: NewsletterState;
  }>,
  AnyAction,
  SagaMiddleware<object>[]
> => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  // configure paperboy whoami api
  const whoamiInstance = WhoamiWrapper.getInstance();
  const paperboyApi: IPaperboyApi = PaperboyApi(
    new WhoamiApi(getApiUrl(), whoamiInstance, requestTimeout)
  );

  sagaMiddleware.run(rootSaga, paperboyApi);

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = Dispatch;
