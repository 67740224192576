import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";

import { ConfigContext } from "@/factory/apps/NoSSOApp";
import { Icons } from "@/styles/styles";

import {
  ErrorIconWrapper,
  ErrorMessage,
  StyledInputField,
  StyledInputWrapper,
  StyledLabel,
} from "./styles";

export type InputFieldProps = {
  onChange: (email: string) => void;
  disabled?: boolean;
  email: string;
  active: boolean;
  error?: boolean;
  errorMessage?: string;
};

const InputField: React.FC<InputFieldProps> = (
  props: InputFieldProps
): JSX.Element => {
  const { iconSourceURL } = React.useContext(ConfigContext);

  const [isActive, setIsActive] = useState(props.active || false);
  const [hasBlur, setBlur] = useState(false);

  useEffect(() => {
    setIsActive(isActive || props.active);
  }, [props.email]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value || "");
    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  return (
    <StyledInputWrapper>
      <StyledInputField
        error={props.error && hasBlur}
        disabled={props.disabled}
        type="email"
        onChange={handleChange}
        value={props.email}
        onBlur={() => setBlur(true)}
      />
      <StyledLabel className={isActive ? "active" : ""} htmlFor="email">
        E-Mail-Adresse
      </StyledLabel>
      {props.error && hasBlur && (
        <ErrorIconWrapper>
          <SVG src={`${iconSourceURL}${Icons.Error}.svg`} />
        </ErrorIconWrapper>
      )}
      {props.error && props.errorMessage && hasBlur && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </StyledInputWrapper>
  );
};

export default InputField;
