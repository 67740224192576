export const container: HTMLElement | null = document.getElementById(
  "la-newsletter-registration"
);

export const microfrontendWidget: HTMLElement | null = document.querySelector(
  '[data-widget-id="newsletter-registration-widget"]'
);

export const enum Brands {
  Bild = "bild",
  Aubi = "aubi",
  Cobi = "cobi",
  Welt = "welt",
}

// LA-2232 Default brand is used as fallback for the old integration on bild and should be deleted
const defaultBrand = Brands.Bild;
export function getBrandIdentifier(): Brands {
  const brand: Brands = container?.dataset.brand as Brands;
  if (!brand) {
    return defaultBrand;
  }
  return brand;
}

export default Brands;
