import styled from "styled-components";

import { blockCenterRelative, flexColumn } from "@/styles/common";
import { Colors, FontFamily } from "@/styles/styles";

export type StyledInputFieldProps = {
  borderRadius: string;
  borderColor: Colors;
  borderWidth: string;
  backgroundColor: Colors;
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;

  hover: {
    borderColor: Colors;
    color: Colors;
  };

  focus: {
    borderColor: Colors;
    color: Colors;
  };

  error: {
    borderColor: Colors;
    color: Colors;
  };

  disabled: {
    borderColor: Colors;
    color: Colors;
  };
};
export const StyledInputWrapper = styled.div`
  ${blockCenterRelative}
  ${flexColumn}
  width: 100%;
  .active {
    transform: translateY(-40%) scale(0.78);
  }
`;
export const StyledInputField = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 0 16px;
  border: ${(props) => props.theme.components.InputField.borderWidth} solid
    ${(props) =>
      props.error
        ? props.theme.components.InputField.error.borderColor
        : props.theme.components.InputField.borderColor};
  background-color: ${(props) =>
    props.theme.components.InputField.backgroundColor};
  border-radius: ${(props) => props.theme.components.InputField.borderRadius};
  color: ${(props) =>
    props.disabled
      ? props.theme.components.InputField.disabled.color
      : props.theme.components.InputField.focus.color};
  font-family: ${(props) => props.theme.components.InputField.fontFamily};
  outline: 0;
  transition-duration: ${(props) => props.theme.global.transitions.duration};
  transition-property: background-color, color, border;
  transition-timing-function: ${(props) =>
    props.theme.global.transitions.speedCurve};
  &:hover {
    border-color: ${(props) =>
      props.error
        ? props.theme.components.InputField.error.borderColor
        : props.disabled
        ? props.theme.components.InputField.disabled.borderColor
        : props.theme.components.InputField.hover.borderColor};
  }

  &:focus {
    border-color: ${(props) =>
      props.error
        ? props.theme.components.InputField.error.borderColor
        : props.disabled
        ? props.theme.components.InputField.disabled.borderColor
        : props.theme.components.InputField.focus.borderColor};
  }

  & + label {
    background-color: ${(props) =>
      props.theme.components.InputField.backgroundColor};
    color: ${(props) =>
      props.error
        ? props.theme.components.InputField.error.color
        : props.disabled
        ? props.theme.components.InputField.disabled.color
        : props.theme.components.InputField.color};
    font-family: ${(props) => props.theme.components.InputField.fontFamily};
    font-size: ${(props) => props.theme.components.InputField.fontSize};
  }

  &:hover + label {
    color: ${(props) =>
      props.error
        ? props.theme.components.InputField.error.color
        : props.disabled
        ? props.theme.components.InputField.disabled.color
        : props.theme.components.InputField.hover.color};
  }

  &:focus + label {
    color: ${(props) =>
      props.error
        ? props.theme.components.InputField.error.color
        : props.disabled
        ? props.theme.components.InputField.disabled.color
        : props.theme.components.InputField.focus.color};
    transform: translateY(-40%) scale(0.78);
    transition-duration: ${(props) => props.theme.global.transitions.duration};
    transition-property: transform;
    transition-timing-function: ${(props) =>
      props.theme.global.transitions.speedCurve};
  }

  /* Change autocomplete styles in webkit for chrome */

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.components.InputField.backgroundColor} inset;
    -webkit-text-fill-color: ${(props) =>
      props.disabled
        ? props.theme.components.InputField.disabled.color
        : props.theme.components.InputField.focus.color};
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  padding: 0 4px;
  margin: 0 14px;
  pointer-events: none;
  text-transform: uppercase;
  transform: translate(0, 14px) scale(1);
  transform-origin: top left;
  transition-duration: ${(props) => props.theme.global.transitions.duration};
  transition-property: background-color, color, border;
  transition-timing-function: ${(props) =>
    props.theme.global.transitions.speedCurve};
`;

export const ErrorIconWrapper = styled.i`
  position: absolute;
  top: 14px;
  right: 16px;
`;

export const ErrorMessage = styled.p`
  padding: 4px 0;
  margin: 0 16px;
  color: ${(props) => props.theme.components.InputField.error.color};
  font-size: 12px;
`;
