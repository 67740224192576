import React from "react";

import { StyledButton } from "./styles";

export type SubmitButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactChild;
};

const SubmitButton: React.FC<SubmitButtonProps> = (
  props: SubmitButtonProps
): JSX.Element => {
  return (
    <StyledButton disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </StyledButton>
  );
};

export default SubmitButton;
